import Entity from '../entity'
import Address from './address'

export default class Student extends Entity<number>{
    code: string;
    document: string;
    document_full: string;
    fullname: string;
    firstname: string;
    lastname: string;
    email: string;
    phone_mobile: string;
    birthdate: string;
    active: boolean;
    gender_id: number;
    document_type_id: number;
    type_id: number;
    group_id: number;
    addresses: Array<Address>;
    
    document_type: any;
    address_full: string;
}


