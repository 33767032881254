import Entity from '../entity'

export default class Attendance extends Entity<number>{
    enrollment_id: number;
    student_id: number;
    attendance_nro: number;
    absent_nro: number;
    avg_attendance: number;
}


