







































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
    //data() {
    //    return {
    //        items: { type: Array }
    //    }
    //},
})

export default class SortableList extends Vue {
    name: string = 'sortableList';
    @Prop({ type: Array, default: () => { return []} }) value: Array<any>;
    @Prop({ type: Array }) items: Array<any>;
    @Prop({ type: String, default: 'name' }) itemText: string;
    @Prop({ type: Array }) model: Array<any>;
    selIndex: number = 0;

    updateModel() {
        this.$emit('input', this.items);
    };

    move(array, index, delta) {
        //var index = array.indexOf(element);
        var newIndex = index + delta;
        if (newIndex < 0 || newIndex == array.length) return; //Already at the top or bottom.
        var indexes = [index, newIndex].sort(); //Sort the indixes
        array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]); //Replace from lowest index, two elements, reverting the order
    }

    up() {
        if (this.selIndex == 0) return;
        this.move(this.items, this.selIndex, -1);
        this.selIndex--;
        this.updateModel();
    }

    down() {
        if (this.selIndex == this.items.length - 1) return;
        this.move(this.items, this.selIndex, 1);
        this.selIndex++;
        this.updateModel();
    }

    selectItem(index) {
        this.selIndex = index;
    }

    created() {
        this.updateModel();
    }
}
