import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Country from '../../entities/preferences/country'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CountryState extends ListState<Country>{
    editModel: Country;
}
class CountryModule extends ListModule<CountryState, any, Country>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Country>(),
        loading:false,
        editModel:new Country(),
        activeList: new Array<Country>(),
        path: 'countries'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const countryModule=new CountryModule();
export default countryModule;