
















import { Component, Vue, Prop } from 'vue-property-decorator';
import AbpBase from '../lib/abpbase';

@Component({
    components: {},
    computed: {
        dialog: {
            get() {
                let obj = (this as any);
                return obj.value
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
})

export default class Loader extends AbpBase {
    @Prop({ type: Boolean, default: false }) value: boolean;
    
    async created() {
        
    }
}
