import Entity from '../entity'
import CertificateTemplatePage from './certificatetemplatepage';

export default class CertificateTemplate extends Entity<number>{
    orientation: string;
    name: string;
    nro_pages: number;

    pages: Array<CertificateTemplatePage>;
}


