import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Position from '../../entities/elections/position'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PositionState extends ListState<Position>{
    editModel:Position;    
}
class PositionModule extends ListModule<PositionState,any,Position>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Position>(),
        loading:false,
        editModel: new Position(),
        activeList: new Array<Position>(),
        path: 'positions'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra 
    });
}
const positionModule=new PositionModule();
export default positionModule;
