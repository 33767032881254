import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Lesson from '../../entities/courses/lesson'
import Util from '../../../lib/util'
import Ajax from '../../../lib/ajax'

interface LessonState extends ListState<Lesson>{
    editModel: Lesson;
    loadingAction: boolean,
}
class LessonModule extends ListModule<LessonState, any, Lesson>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Lesson>(),
        activeList: new Array<Lesson>(),
        loading: false,
        loadingAction: false,
        editModel: new Lesson(),
        path: 'lessons',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async open(context: ActionContext<LessonState, any>, payload: any) {
            context.state.loadingAction = true;
            let response = await Ajax.post('/api/lessons/open', payload.data).catch(e => {
                context.state.loadingAction = false;
                return Promise.reject(e);
            });
            context.state.loadingAction = false;
            return response.data.result;
        },
        async stop(context: ActionContext<LessonState, any>, payload: any) {
            context.state.loadingAction = true;
            let response = await Ajax.post('/api/lessons/stop', payload.data).catch(e => {
                context.state.loadingAction = false;
                return Promise.reject(e);
            });
            context.state.loadingAction = false;
            return response.data.result;
        },
        async close(context: ActionContext<LessonState, any>, payload: any) {
            context.state.loadingAction = true;
            let response = await Ajax.post('/api/lessons/close', payload.data).catch(e => {
                context.state.loadingAction = false;
                return Promise.reject(e);
            });
            context.state.loadingAction = false;
            return response.data.result;
        },
        async cancel(context: ActionContext<LessonState, any>, payload: any) {
            context.state.loadingAction = true;
            let response = await Ajax.post('/api/lessons/cancel', payload.data).catch(e => {
                context.state.loadingAction = false;
                return Promise.reject(e);
            });
            context.state.loadingAction = false;
            return response.data.result;
        },
        async update(context: ActionContext<LessonState, any>, payload: any) {
            await Ajax.put('/api/lessons/update/' + payload.data.id, payload.data).catch(e => {
                return Promise.reject(e);
            });
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const lessonModule=new LessonModule();
export default lessonModule;
