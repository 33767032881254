import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ExchangeRate from '../../entities/preferences/exchangerate'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ExchangeRateState extends ListState<ExchangeRate>{
    editModel:ExchangeRate;
    currency:number;
}
class ExchangeRateModule extends ListModule<ExchangeRateState,any,ExchangeRate>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ExchangeRate>(),
        loading:false,
        editModel: new ExchangeRate(),
        currency: 0,
        path: 'currencies'  
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastRates(context: ActionContext<ExchangeRateState, any>, payload: any) {
            context.state.loading = true; 
            let reponse = await Ajax.get('/api/currencies/' + payload.id + '/last-rates', { params: payload }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            //console.log(reponse.data.result);
            context.state.list = reponse.data.result;
            context.state.totalCount = reponse.data.result.length;
            context.state.loading = false;
        },
        async create(context: ActionContext<ExchangeRateState, any>, payload: any) {
            context.state.loading = true;
            payload.data.currency_id = context.state.currency;
            var result = await Ajax.post('/api/currency-rates', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list.push(result.data.result);
            context.state.loading = false;
        },
        async update(context: ActionContext<ExchangeRateState, any>, payload: any) {
            context.state.loading = true;
            payload.data.currency_id = context.state.currency;
            var result = await Ajax.put('/api/currency-rates/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async updateLastRate(context: ActionContext<ExchangeRateState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/update-last-rate').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ExchangeRateState, model: any) {
            var today = new Date();

            state.editModel = Object.assign({});
            state.editModel.process_date = today.toISOString().substr(0, 10);
        },
        setCurrency(state: ExchangeRateState, id: number) {
            var today = new Date();
            state.currency = id;
        },
    });
}
const exchangerateModule=new ExchangeRateModule();
export default exchangerateModule;