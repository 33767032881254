import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Certificate from '../../entities/courses/certificate'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface CertificateState extends ListState<Certificate>{
    editModel: Certificate;
    activeList: Array<Certificate>;
    loadingSent: boolean,
}
class CertificateModule extends ListModule<CertificateState, any, Certificate>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Certificate>(),
        loading: false,
        loadingSent: false,
        editModel:new Certificate(),
        activeList: new Array<Certificate>(),
        path: 'certificates'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async sent(context: ActionContext<CertificateState, any>, payload: any) {
            context.state.loadingSent = true;
            let response = await Ajax.post('/api/certificates/sent', payload.data).catch(e => {
                context.state.loadingSent = false;
                return Promise.reject(e);
            });
            context.state.loadingSent = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const certificateModule=new CertificateModule();
export default certificateModule;
