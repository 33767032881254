import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Blacklist from '../../entities/elections/blacklist'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BlacklistState extends ListState<Blacklist>{
    editModel: Blacklist;
    reportList: Array<any>;
    filename: string; 
}
class BlacklistModule extends ListModule<BlacklistState,any,Blacklist>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Blacklist>(),
        loading:false,
        editModel: new Blacklist(),
        activeList: new Array<Blacklist>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'blacklists'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async reportBlacklist(context: ActionContext<BlacklistState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/blacklists', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra 
        new(state: BlacklistState, model: Blacklist) {
            state.editModel = Object.assign({});
            if (model != null)
                state.editModel.election_id = model.election_id;
        },
    });
}
const blacklistModule=new BlacklistModule();
export default blacklistModule;
