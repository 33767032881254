import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Teacher from '../../entities/courses/teacher'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface TeacherState extends ListState<Teacher>{
    editModel: Teacher;
}
class TeacherModule extends ListModule<TeacherState, any, Teacher>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Teacher>(),
        loading:false,
        editModel:new Teacher(),
        activeList: new Array<Teacher>(),
        path: 'teachers'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<TeacherState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/teachers/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: TeacherState, model: any) {
            state.editModel = Object.assign({});
            state.editModel.code = '';
        },
    });
}
const teacherModule=new TeacherModule();
export default teacherModule;
