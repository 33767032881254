































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
    computed: {
    },
})

export default class CusDatePicker extends Vue {
    name: string = 'cusDatePicker'; 
    @Prop({ type: Object }) item: any;
    @Prop({ type: String }) value: string;
    visible: boolean = false;
    dateFormatted: string = '';

    get date() {
        this.dateFormatted = this.formatDate(this.value);
        return this.dateFormatted;
    };

    set date(value) {
        this.dateFormatted = this.formatDate(value);
        this.$emit('input', value);
    };

    dateFormattedChange(value:string) {
        if (value == null) return;
        if (value.length == 10)
            this.$emit('input', value);
    };

    formatDate(date) {
        if (!date) return null;
        date = date.substr(0, 10);
        return date;
    };

    updateModel(value: string) {
        this.visible = false;
        this.$emit('input', value);
    };

    created() {

    }
}
