

































import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import sidebarMenu from './components/sidebarMenu.vue';
import sidebarMenuShrink from './components/sidebarMenuShrink.vue';
import util from '../../lib/util';
import AppConst from '../../lib/appconst'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
    components: { VuePerfectScrollbar, sidebarMenu, sidebarMenuShrink },
    data: () => ({
        mini: false,
        drawer: true,
        scrollSettings: {
            maxScrollbarLength: 160
        }
    }),
    watch: {
        shrink: function (val) {
            let obj = (this as any);
            if (this.$vuetify.breakpoint.xsOnly) {
                obj.drawer = obj.shrink;
            }
        },
        drawer: function (val) {
            let obj = (this as any);
            if (!val && this.$vuetify.breakpoint.xsOnly) {
                //this.$emit('hide-drawer');
                this.$store.commit('app/toggleShrink');
            }
        },
    }
})
export default class ShrinkableMenu extends Vue {
    name: string = 'shrinkableMenu';
    @Prop({ required: true, type: Array }) menuList: Array<any>;
    @Prop({ type: Array }) openNames: Array<string>;
    @Prop({ type: Function }) beforePush: Function;
    @Prop({ validator: (val) => { return util.oneOf(val, ['dark', 'light']); } }) theme: string;

    get shrink() {
        return this.$store.state.app.shrink;
    }
    get bgColor() {
        return this.theme === 'dark' ? '#001529' : '#fff';
    }
    get shrinkIconColor() {
        return this.theme === 'dark' ? '#fff' : '#495060';
    }
    get loading() {
        return this.$store.state.tab.loadingTab;
    }
    get nroTabs() {
        return AppConst.setting.nroTabs;
    }
    @Emit('on-change')
    handleChange(name: string) {
        //console.log("ShrinkableMenu - " + name);
        //let willpush = true;
        //if (this.beforePush !== undefined) {
        //    if (!this.beforePush(name)) {
        //        willpush = false;
        //    }
        //}
        //if (willpush) {
        //    this.$router.push({name:name})
        //}
    }

    created() {
        (this as any).drawer = !this.$vuetify.breakpoint.xsOnly;
    }
}
