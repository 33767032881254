import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import VoteResumen from '../../entities/elections/voteresumen'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface VoteResumenState extends ListState<VoteResumen>{
    editModel:VoteResumen;
    
}
class VoteResumenModule extends ListModule<VoteResumenState,any,VoteResumen>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<VoteResumen>(),
        loading:false,
        editModel: new VoteResumen(),
        activeList: new Array<VoteResumen>(),
        path: 'vote-resumen'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: VoteResumenState, model: VoteResumen) {
            state.editModel = Object.assign({});
        },
        edit(state: VoteResumenState, model: VoteResumen) {
            state.editModel = model;
        },
    });
}
const voteresumenModule=new VoteResumenModule();
export default voteresumenModule;
