import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Payment from '../../entities/lawyers/payment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PaymentState extends ListState<Payment>{
    editModel:Payment;    
}
class PaymentModule extends ListModule<PaymentState,any,Payment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Payment>(),
        loading:false,
        editModel: new Payment(),
        activeList: new Array<Payment>(),
        path: 'payments'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<PaymentState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/payments/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PaymentState, model: Payment) {
            state.editModel = Object.assign({});
            state.editModel.code = '00000000';
            state.editModel.currency_id = 1;
            state.editModel.payment_date = Util.abp.clock.today();
            if (model != undefined && model.lawyer != undefined)
                state.editModel.lawyer = model.lawyer;
        },
        edit(state: PaymentState, model: Payment) {
            state.editModel = model;
        },
    });
}
const paymentModule=new PaymentModule();
export default paymentModule;
