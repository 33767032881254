import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CertificateTemplate from '../../entities/courses/certificatetemplate'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import CertificateTemplatePage from '@/store/entities/courses/certificatetemplatepage';

interface CertificateTemplateState extends ListState<CertificateTemplate>{
    editModel: CertificateTemplate;
    activeList: Array<CertificateTemplate>;
    pages: Array<CertificateTemplatePage>;
}
class CertificateTemplateModule extends ListModule<CertificateTemplateState, any, CertificateTemplate>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CertificateTemplate>(),
        loading: false,
        editModel:new CertificateTemplate(),
        activeList: new Array<CertificateTemplate>(),
        pages: new Array<CertificateTemplatePage>(),
        path: 'certificate-templates'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async uploadPageImage(context: ActionContext<CertificateTemplateState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/certificate-templates-upload', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<CertificateTemplateState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: CertificateTemplateState, model: CertificateTemplate) {
            state.editModel = model;
            state.pages = model.pages;
        },
    });
}
const certificatetemplateModule=new CertificateTemplateModule();
export default certificatetemplateModule;
