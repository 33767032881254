import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Election from '../../entities/elections/election'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import ElectionApplicant from '@/store/entities/elections/electionapplicant';

interface ElectionState extends ListState<Election>{
    editModel: Election;
    viewModel: Election;
    hisLoading: boolean;
    getLoading: boolean;
    appLoading: boolean;
    activeList: Array<Election>;
    applicants: Array<ElectionApplicant>;
    history: Array<any>;    
}
class ElectionModule extends ListModule<ElectionState, any, Election>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Election>(),
        activeList: new Array<Election>(),
        loading: false,
        hisLoading: false,
        getLoading: false,
        appLoading: false,
        editModel: new Election(),
        history: new Array<any>(),
        applicants: new Array<ElectionApplicant>(),
        path: 'elections',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAllActive(context: ActionContext<ElectionState, any>, payload: any) {
            let force = (payload.data != undefined && payload.data['force'] == true ? true : false);
            if (context.state.activeList != undefined && context.state.activeList.length > 0 || (context.state.loading && !force)) return true;
            let params = (payload.data == undefined ? {} : payload.data);
            delete params['force'];
            params.perPage = -1;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/elections', { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            if (context.state.activeList != undefined)
                context.state.activeList = reponse.data.result;
            context.state.activeList.forEach(l => {
                l.state_color = Util.getTypeValueById(l.state_id).extra;
            });

            context.state.loading = false;
        },
        async get(context: ActionContext<ElectionState, any>, payload: any) {
            if (payload.id == undefined) return {};            
            context.state.getLoading = true;
            let reponse = await Ajax.get('/api/elections/get/' + payload.id);
            let item = reponse.data.result as Election;
            context.state.getLoading = false;
            return item;
        },
        async getByCode(context: ActionContext<ElectionState, any>, payload: any) {
            if (payload.code == undefined) return {};
            context.state.getLoading = true;
            let reponse = await Ajax.get('/api/elections/get-by-code', { params: { code: payload.code}});
            let item = reponse.data.result as Election;
            context.state.getLoading = false;
            return item;
        },
        async getHistory(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/elections-history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async confirm(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/elections/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async start(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/elections/start', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async stop(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/elections/stop', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async generatePassword(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/elections/passwords', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async close(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/elections/close', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async getLastNumber(context: ActionContext<ElectionState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/elections/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },

        async getApplicants(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.appLoading = true;
            let reponse = await Ajax.get('/api/elections-applicants', { params: payload.data }).catch(e => {
                context.state.appLoading = false;
                return Promise.reject(e);
            });
            context.state.appLoading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.applicants = reponse.data.result;
            return context.state.list;
        },
        async createApplicant(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.appLoading = true;
            let response = await Ajax.post('/api/elections-applicants', payload.data).catch(e => {
                context.state.appLoading = false;
                return Promise.reject(e);
            });
            context.state.appLoading = false;
            if (response.data.result != null)
                context.state.applicants.push(response.data.result);
            return response.data.result;
        },
        async updateApplicant(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.appLoading = true;
            let response = await Ajax.put('/api/elections-applicants/' + payload.data.id, payload.data).catch(e => {
                context.state.appLoading = false;
                return Promise.reject(e);
            });
            context.state.appLoading = false;
            return response.data.result;
        },
        async deleteApplicant(context: ActionContext<ElectionState, any>, payload: any) {
            context.state.appLoading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/elections-applicants/' + id).catch(e => {
                context.state.appLoading = false;
                return Promise.reject(e);
            });
            context.state.appLoading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ElectionState, model: Election) {
            var today = new Date();            
            state.editModel = Object.assign({});
            state.editModel.voter_type = Util.abp.setting.get('VOTER_OPTION_DEFAULT');
            state.editModel.security_level_id = Util.abp.setting.get('SECURITY_LEVEL_DEFAULT');
            state.editModel.type_id = Util.abp.list.get('T_ELECTION','LAW').id;
            state.editModel.number = 0;
            state.editModel.number_formated = '0000';
            state.editModel.nro_options = 1;
            state.editModel.nro_winners = 1;
            state.editModel.with_schedule = false;
            state.applicants = [];
            
        },
        edit(state: ElectionState, model: Election) {
            state.editModel = model;
            state.applicants = [];
            if (model != null && model.applicants != null)
                state.applicants = model.applicants;

        },
        view(state: ElectionState, model: Election) {
            state.editModel = model;
            state.applicants = [];
            if (model != null && model.applicants != null)
                state.applicants = model.applicants;
        },
        addApplicant(state: ElectionState, lawyer: any) {
            if (state.applicants == null || state.applicants == undefined) state.applicants = [];
            let exist = state.applicants.filter((detail: any) => {
                return detail.lawyer_id == lawyer.id;
            });

            if (exist.length > 0) {
                
            } else {
                let detail = new ElectionApplicant();
                detail.lawyer_id = lawyer.id;
                detail.position_id = 1;
                detail.name = lawyer.fullname;
                detail.votes = 0;
                detail.winner = false;

                state.applicants.push(detail);
            }
        },
        addItem(state: ElectionState, text: any) {
            if (state.applicants == null || state.applicants == undefined) state.applicants = [];
            let detail = new ElectionApplicant();
            detail.name = text;
            detail.votes = 0;
            detail.winner = false;

            state.applicants.push(detail);
        },
        deleteApplicant(state: ElectionState, index: number) {
            state.applicants.splice(index, 1);
        },
        setApplicants(state: ElectionState, list: Array<any>) {
            state.applicants = list;
        },
    });
}
const electionModule=new ElectionModule();
export default electionModule;
