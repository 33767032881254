import Entity from '../entity'
import Course from './course';
import Teacher from './teacher';

export default class CourseClass extends Entity<number>{
    course_id: number;
    schedule_id: number;
    teacher_id: number;
    fee_id: number;
    state_id: number;
    title: string;
    theme: string;
    teachers_text: string;
    capacity: number;
    enrolled_students: number;
    min_score: number;
    min_attendance: number;
    start_date: string;
    end_date: string;
    last_enroll_day: string;
    enrolling: boolean;
    payment_type: string;
    fixed_link: string;
    in_process: boolean;
    finished: boolean;
    certified: boolean;
    marks_timeout: number;
    academic_hours: number;
    prefix_code: string;

    course: Course;
    teacher: Teacher;
}


