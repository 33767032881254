import ajax from '../../lib/ajax';
import util from '../../lib/util'
import {Store,Module,ActionContext} from 'vuex' 
interface SessionState{
    application: any,
    user: any,
    company: any
}
class SessionStore implements Module<SessionState,any>{
    namespaced=true;
    state={
        application: null,
        user: null,
        company: null
    }
    actions={
        async init(content:ActionContext<SessionState,any>){
            let rep = await ajax.get('/api/auth/getCurrentLoginInformations',{headers:{}});
            content.state.application = rep.data.result.application;
            content.state.user = rep.data.result.user;
            content.state.company = rep.data.result.company;
        }
    }
    mutations = {
        setCompany(state: SessionState, company: any) {
            if (company != null) {
                state.company = company;
            }
        },
        setUser(state: SessionState, user: any) {
            if (user != null) {
                state.user = user;
                state.user.full_name = state.user.first_name + " " + state.user.last_name;
            }
        }
    }
}
const session=new SessionStore();
export default session;