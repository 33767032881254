import Entity from '../entity'
import ElectionApplicant from './electionapplicant';

export default class Election extends Entity<number>{
    state_id: number;
    type_id: number;
    number: number;
    code: string;
    description: string;
    voting_date: string;
    voter_type: number;
    security_level_id: number;
    start_hour: string;
    end_hour: string;
    with_schedule: boolean;
    nro_options: number;
    nro_winners: number;
    nro_applicants: number;
    notes: string;
    valid: boolean;
    started: boolean;
    in_proccess: boolean;
    finish_date: string;
    total_voters: number;
    votes: number;
    absents: number;
    banned: number;
    voided: number;
    winner_id: number;
    winners: string;

    state_color: string;
    number_formated: string;
    security_level: string;
    nro_passwords: number;
    applicants: Array<ElectionApplicant>;
}


