import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import user from './modules/admin/user'
import role from './modules/admin/role'
import tab from './modules/admin/tab'
import company from './modules/admin/company'
import dashboard from './modules/admin/dashboard'
  
import student from './modules/students/student'
import address from './modules/students/address'
import documenttype from './modules/students/documenttype'
import group from './modules/students/group'
import gender from './modules/students/gender'
 
import attendance from './modules/courses/attendance'
import courseclass from './modules/courses/courseclass'
import lesson from './modules/courses/lesson'
import teacher from './modules/courses/teacher'
import enrollment from './modules/courses/enrollment'
import schedule from './modules/courses/schedule'
import course from './modules/courses/course'
import fee from './modules/courses/fee'
import certificate from './modules/courses/certificate'
import certificatetemplate from './modules/courses/certificatetemplate'

import lawyer from './modules/lawyers/lawyer'
import payment from './modules/lawyers/payment'

import voteresumen from './modules/elections/voteresumen'
import election from './modules/elections/election'
import position from './modules/elections/position'
import blacklist from './modules/elections/blacklist'
import vote from './modules/elections/vote'

import application from './modules/registryoffices/application'
import trade from './modules/registryoffices/trade'

import video from './modules/videos/video'

import country from './modules/preferences/country'
import department from './modules/preferences/department'
import province from './modules/preferences/province'
import district from './modules/preferences/district'
import currency from './modules/preferences/currency'
import exchangerate from './modules/preferences/exchangerate'
import tax from './modules/preferences/tax'
import generaltype from './modules/preferences/generaltype'
import generaltypevalue from './modules/preferences/generaltypevalue'
import configuration from './modules/preferences/configuration'



const store = new Vuex.Store({
    state: {
        //
        //loading: boolean
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        user,
        role,
        tab,
        company,
        dashboard,

        student,
        address,
        documenttype,
        group,
        gender,

        attendance,
        courseclass,
        lesson,
        teacher,
        enrollment,
        schedule,
        course,
        fee,
        certificate,
        certificatetemplate,

        lawyer,
        payment,

        voteresumen,
        election,
        position,
        blacklist,
        vote,

        application,
        trade,

        video,

        country,
        department,
        province,
        district,
        currency,
        exchangerate,
        tax,
        configuration,
        generaltype,
        generaltypevalue,
    }
});

export default store;