import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import User from '../../entities/admin/user'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface UserState extends ListState<User> {
    editModel: User;

}
class UserModule extends ListModule<UserState, any, User>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<User>(),
        loading: false,
        editModel: new User(),
        activeList: new Array<User>(),
        path: 'users'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            payload.data['confirmed'] = '1';

            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (response.data.result)
                (context.state as any).activeList.push(response.data.result);
            return response.data.result;
        },
        async update(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            (context.state as any).activeList = [];
        },
        async updateProfile(context: ActionContext<UserState, any>, payload: any) {
            context.state.loading = true;

            if (payload.data.roles == undefined && payload.data.rol_id != null)
                payload.data.roles = [payload.data.rol_id];

            let response = await Ajax.put('/api/user/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            (context.state as any).activeList = [];
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const userModule = new UserModule();
export default userModule;

