import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Enrollment from '../../entities/courses/enrollment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface EnrollmentState extends ListState<Enrollment>{
    editModel: Enrollment;
    activeList: Array<Enrollment>;
    reportList: Array<any>;
    hisLoading: boolean;
    history: Array<any>;    
}
class EnrollmentModule extends ListModule<EnrollmentState, any, Enrollment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Enrollment>(),
        loading: false,
        hisLoading: false,
        editModel:new Enrollment(),
        activeList: new Array<Enrollment>(),
        reportList: new Array<any>(),
        history: new Array<any>(),
        path: 'enrollments'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<EnrollmentState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.get('/api/enrollments', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Enrollment>;

            context.state.totalCount = page.total;
            context.state.list = page.data;
            context.state.list.forEach((item: any) => {
                item.stateColor = Util.abp.list.getItem(item.state_id).extra;
            });
        },
        async getHistory(context: ActionContext<EnrollmentState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/enrollments/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async authorize(context: ActionContext<EnrollmentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/enrollments/authorize', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async importBecados(context: ActionContext<EnrollmentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/enrollments/import-becados',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
            return true;
        },
        async createBatch(context: ActionContext<EnrollmentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/enrollments/batch-create', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async delete(context: ActionContext<EnrollmentState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/enrollments/reject', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setReportList(state: EnrollmentState, list: Array<any>) {
            state.reportList = list;
        },
    });
}
const enrollmentModule=new EnrollmentModule();
export default enrollmentModule;
