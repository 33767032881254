import Entity from '../entity'
import Lawyer from './lawyer';

export default class Payment extends Entity<number>{
    lawyer_id: number;
    currency_id: number;
    condition_id: number;
    code: string;
    amount: number;
    conversion_rate: number;
    payment_date: string;
    expiration_date: string;
    valid: boolean;

    lawyer: Lawyer;
}


