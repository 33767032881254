import Entity from '../entity'

export default class Province extends Entity<number>{
    name: string;
    code: string;
    active: boolean;
    department_id: number;
}


