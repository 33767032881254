import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Lawyer from '../../entities/lawyers/lawyer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface LawyerState extends ListState<Lawyer>{
    editModel:Lawyer;
    reportList: Array<any>;
    filename: string;
}
class LawyerModule extends ListModule<LawyerState,any,Lawyer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Lawyer>(),
        loading:false,
        editModel: new Lawyer(),
        activeList: new Array<Lawyer>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'lawyers'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<LawyerState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/lawyers/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
        async getByDocument(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/lawyer-document', { params: payload.data });
            context.state.loading = false;
            return reponse.data.result;
        },
        async importEnabled(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/lawyers/import',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
            return true;
        },
        async importNews(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/lawyers/import-new',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = response.data.result;
            return true;
        },
        async createBatch(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/lawyers/batch-create', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async updateBatch(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/lawyers/batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },

        async reportQueryLawyers(context: ActionContext<LawyerState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/query-lawyers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: LawyerState, model: Lawyer) {
            state.editModel = Object.assign({});
            state.editModel.code = '0';
            state.editModel.registration_date = Util.abp.clock.today();
            state.editModel.document_type_id = Util.abp.setting.get('DEFAULT_DOCUMENT');            
            state.editModel.active = true;            
            state.editModel.alive = true;            

            state.editModel.address = Object.assign({});
            state.editModel.address_legal = Object.assign({});
            state.editModel.address_work = Object.assign({});

            state.editModel.address.country_id = 171;
            state.editModel.address_legal.country_id = 171;
            state.editModel.address_work.country_id = 171;
        },
        edit(state: LawyerState, model: Lawyer) {
            state.editModel = model;

            if (state.editModel.address == null) {
                state.editModel.address = Object.assign({});
                state.editModel.address.country_id = 171;
            } else {
                state.editModel.address.ubigeo = {
                    department: state.editModel.address.department_id,
                    province: state.editModel.address.province_id,
                    district: state.editModel.address.district_id
                }
            }

            if (state.editModel.address_legal == null) {
                state.editModel.address_legal = Object.assign({});
                state.editModel.address_legal.country_id = 171;
            } else {
                state.editModel.address_legal.ubigeo = {
                    department: state.editModel.address_legal.department_id,
                    province: state.editModel.address_legal.province_id,
                    district: state.editModel.address_legal.district_id
                }
            }

            if (state.editModel.address_work == null) {
                state.editModel.address_work = Object.assign({});
                state.editModel.address_work.country_id = 171;
            } else {
                state.editModel.address_work.ubigeo = {
                    department: state.editModel.address_work.department_id,
                    province: state.editModel.address_work.province_id,
                    district: state.editModel.address_work.district_id
                }
            }
        },
        setReportList(state: LawyerState, list: Array<any>) {
            state.reportList = list;
        },
    });
}
const lawyerModule=new LawyerModule();
export default lawyerModule;
