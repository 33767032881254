import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Fee from '../../entities/courses/fee'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface FeeState extends ListState<Fee>{
    editModel: Fee;
}
class FeeModule extends ListModule<FeeState, any, Fee>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Fee>(),
        loading:false,
        editModel:new Fee(),
        activeList: new Array<Fee>(),
        path: 'fees'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getByType(context: ActionContext<FeeState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/fee-by-type', { params: payload.data });
            context.state.loading = false;
            return reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const feeModule=new FeeModule();
export default feeModule;