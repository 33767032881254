import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Application from '../../entities/registryoffices/application'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ApplicationState extends ListState<Application>{
    editModel: Application;
    hisLoading: boolean;
    history: Array<any>;
    reportList: Array<any>;
    filename: string; 
    
}
class ApplicationModule extends ListModule<ApplicationState,any,Application>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Application>(),
        loading: false,
        hisLoading: false,
        editModel: new Application(),
        activeList: new Array<Application>(),
        history: new Array<any>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'applications'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getHistory(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/applications-history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async reportApplication25(context: ActionContext<ApplicationState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/application-25', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ApplicationState, model: Application) {
            state.editModel = Object.assign({});
        },
        edit(state: ApplicationState, model: Application) {
            state.editModel = model;
        },
    });
}
const applicationModule=new ApplicationModule();
export default applicationModule;
