import Entity from '../entity'

export default class Trade extends Entity<number>{
    laywer_id: number;
    area_id: number;
    state_id: number;
    name: string;
    record: string;
    account: string;
    cci: string;
    application_date: string;
    filename_request: string;
    amount: number;
    filename_response: string;
    response: string;
    comments: string;
}


