import Entity from '../entity'

export default class Fee extends Entity<number>{
    student_type_id: number;
    name: string;
    amount: number;
    active: boolean;
}


