








import { Component, Vue, Prop } from 'vue-property-decorator';
import AbpBase from '../lib/abpbase';
import Util from '../lib/util';

@Component({
    components: {},
    watch: {
        loading: function (val, oldValue) {    
            console.log(val);
            if (val && !oldValue) {
                (this as any).startLoadingBar();
            }
            else if (!val && oldValue) {
                (this as any).stopLoadingBar();
            }
        }
    }
})

export default class LoadingBar extends AbpBase {
    value: number = 0;
    query: boolean = false;
    show: boolean = false;
    interval: number = 0;

    beforeDestroy() {
        clearInterval(this.interval)
    }

    get loading() {
        return this.$store.state.app.loading;
    }

    startLoadingBar() {
        this.query = true
        this.show = true
        console.log('startLoadingBar');
    }

    stopLoadingBar() {
        setTimeout(() => {
            this.query = false
            this.show = false
        }, 1000)
        console.log('stopLoadingBar');
    }

    queryAndIndeterminate() {
        if (!this.show) return;
        this.query = true
        //this.show = true
        this.value = 0

        setTimeout(() => {
            this.query = false

            this.interval = setInterval(() => {
                if (this.value === 100) {
                    clearInterval(this.interval)
                    //this.show = false
                    return setTimeout(this.queryAndIndeterminate, 2000)
                }
                this.value += 25
            }, 1000)
        }, 2500)
    }

    async created() {
        
    }

    async mounted() {
        //this.queryAndIndeterminate()
        //this.startLoadingBar();
    }
}
