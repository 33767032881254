import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Student from '../../entities/students/student'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import axios from 'axios'
import Address from '../../entities/students/address'

interface StudentState extends ListState<Student>{
    editModel:Student;
    
}
class StudentModule extends ListModule<StudentState,any,Student>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Student>(),
        loading:false,
        editModel: new Student(),
        activeList: new Array<Student>(),
        path: 'students'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<StudentState, any>, payload: any) {
            context.state.loading = true;
            //payload.data.addresses = context.state.editModel.addresses;
            //payload.data.addresses.forEach(item => {
            //    item.country_id = (item.country_id != null && typeof item.country_id == "object" ? item.country_id.id : item.country_id);
            //    item.department_id = (item.department_id != null && typeof item.department_id == "object" ? item.department_id.id : item.department_id);
            //    item.province_id = (item.province_id != null && typeof item.province_id == "object" ? item.province_id.id : item.province_id);
            //    item.district_id = (item.district_id != null && typeof item.district_id == "object" ? item.district_id.id : item.district_id);
            //});

            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<StudentState, any>, payload: any) {
            context.state.loading = true;
            //payload.data.addresses = context.state.editModel.addresses;
            //payload.data.addresses.forEach(item => {
            //    item.country_id = (item.country_id != null && typeof item.country_id == "object" ? item.country_id.id : item.country_id);
            //    item.department_id = (item.department_id != null && typeof item.department_id == "object" ? item.department_id.id : item.department_id);
            //    item.province_id = (item.province_id != null && typeof item.province_id == "object" ? item.province_id.id : item.province_id);
            //    item.district_id = (item.district_id != null && typeof item.district_id == "object" ? item.district_id.id : item.district_id);
            //});

            await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getLastCode(context: ActionContext<StudentState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/students/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
        async getByDocument(context: ActionContext<StudentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/student-document', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: StudentState, model: Student) {
            state.editModel = Object.assign({});
            state.editModel.code = '';
            state.editModel.document_type_id = 1;
            state.editModel.gender_id = 1;
            state.editModel.group_id = 1;
        },
        edit(state: StudentState, model: Student) {
            state.editModel = model;
        },
    });
}
const studentModule=new StudentModule();
export default studentModule;
