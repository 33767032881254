<template>
    <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel" >
        <v-card class="confirm-dialog"> 
            <!--<v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>-->
            <v-card-text v-show="!!message">
                <v-row dense class="d-flex flex-wrap text-center justify-space-around">
                    <v-col cols="12" lg="12" >
                        <v-icon size="120px" color="#F8BB86">mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="12" lg="12" v-show="!!title">
                        <h2 v-html="title"></h2>
                    </v-col>
                    <v-col cols="12" lg="12" v-show="!!message" class="message" v-html="message"></v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="gray" depressed @click.native="cancel">{{options.cancelText}}</v-btn>
                <v-btn color="error" depressed @click.native="agree">{{options.okText}}</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    /**
     * Vuetify Confirm Dialog component
     *
     * Insert component where you want to use it:
     * <confirm ref="confirm"></confirm>
     *
     * Call it:
     * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
     * Or use await:
     * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
     *   // yes
     * }
     * else {
     *   // cancel
     * }
     *
     * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
     * <template>
     *   <v-app>
     *     ...
     *     <confirm ref="confirm"></confirm>
     *   </v-app>
     * </template>
     *
     * mounted() {
     *   this.$root.$confirm = this.$refs.confirm.open
     * }
     */
    export default {
        data: () => ({
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                color: 'primary',
                width: 450,
                zIndex: 200,
                cancelText: 'No',
                okText: 'Si, Seguro',
            } 
        }),
        methods: {
            open(title, message, options) {
                this.dialog = true
                this.title = title
                this.message = message
                this.options = Object.assign(this.options, options)
                return new Promise((resolve, reject) => {
                    this.resolve = resolve
                    this.reject = reject
                })
            },
            agree() {
                //this.choose(true);
                this.resolve(true)
                this.dialog = false
            },
            cancel() {
                //this.choose(false);
                this.resolve(false)
                this.dialog = false
            },
            //onEnterPressed(e) {
            //    if (e.keyCode === 13) {
            //        e.stopPropagation()
            //        this.choose(true)
            //    }
            //},
            //choose(value) {
            //    this.dialog = false
            //    this.$emit('result', value)
            //    this.value = value
            //    this.$destroy()
            //},
            //change(res) {
            //    this.$destroy()
            //}
        },
        //mounted() {
        //    document.addEventListener('keyup', this.onEnterPressed)
        //},
        //destroyed() {
        //    document.removeEventListener('keyup', this.onEnterPressed)
        //},
    }
</script>