import url from './url'
const AppConsts= {
    userManagement:{
        defaultAdminUserName: 'admin'
    },
    localization:{
        defaultLocalizationSourceName: 'CAA'
    },
    authorization:{
        encrptedAuthTokenName: 'enc_auth_token'
    },
    setting: {
        shrink: false,
        confirmLeave: true,
        nroTabs: 7,
        timeout: 480000,
        appName: 'Colegio de Abogados',
    },
    appBaseUrl: "http://localhost:8080",
    remoteServiceBaseUrl: url

}
export default AppConsts