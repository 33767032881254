import Entity from '../entity'

export default class Course extends Entity<number>{
    code: string;
    name: string;
    description: string;
    duration_type: string;
    duration: number;
    academic_hours: number;
    fee_public_id: number;
    fee_lawyer_id: number;
    fee_lawyer_old_id: number;
    active: boolean;

    image_url: string;
    imageData: string;
}


