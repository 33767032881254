










































import tagsPageOpened from '../components/tags-page-opened.vue';
import breadcrumbNav from '../components/breadcrumb-nav.vue';
import fullScreen from '../components/fullscreen.vue';
import lockScreen from '../components/lockscreen/lockscreen.vue';
import shortKeys from '../components/shortkeys/shortkeys.vue';
import notice from '../components/notices/notice.vue';
import LoadingBar from '../components/progress.vue';
import util from '../lib/util';
//import LanguageList from '../components/language-list.vue'
import AbpBase from '../lib/abpbase'
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({
    components: { tagsPageOpened, breadcrumbNav, fullScreen, lockScreen, shortKeys, notice, LoadingBar } //LanguageList
})

export default class AppToolbar extends AbpBase {
    name: string = 'app-toolbar';
    get shrink() {
        return this.$store.state.app.shrink;
    }
    get userName() {
        return this.$store.state.session.user ? this.$store.state.session.user.full_name : ''
    }
    get currentPath() {
        //return this.$store.state.app.currentPath;
        let breadcrumbs = [];
        this.$store.state.app.currentPath.forEach(item => {
            if (item.meta.title != '') {
                breadcrumbs.push({
                    text: item.meta.title,
                    href: '#' + item.path
                });
            }
        });
        return breadcrumbs;
    }
    get title() {
        let text = '';
        this.$store.state.app.currentPath.forEach(item => {
            text = item.meta.title;
        });
        return text;
    }
    isFullScreen: boolean = false;
    get items(): Array<any> {
        let list: Array<any> = [
            {
                icon: 'account_circle',
                name: 'profile',
                title: 'Perfil',
                click: (e) => {
                    //util.openNewPage(this, 'ownspace_index', null, null);
                    //this.$router.push({
                    //    name: 'ownspace_index'
                    //});
                }
            },                
        ];

        if (this.hasPermission('Admin.Settings.view'))
            list.push({
                icon: 'settings',
                name: 'settings',
                title: this.L('Configuracion'),
                click: (e) => {

                }
            });

        list.push({
            icon: 'exit_to_app',
            href: '#',
            title: this.L('Cerrar Sesion'),
            click: (e) => {
                this.$store.commit('app/logout', this);
                util.abp.auth.clearToken();
                location.reload();
            }
        });

        return list;
    };
    get avatarUrl() {
        return (this.$store.state.session.user != null && this.$store.state.session.user.avatar_url != null ? this.$store.state.session.user.avatar_url : '/static/avatar/man_6.jpg');
    }
    fullscreenChange(isFullScreen: boolean) {
        //console.log(isFullScreen);
    }
    @Emit('toggle-click')
    toggleClick() {
        this.$store.commit('app/toggleShrink');
    }
} 
