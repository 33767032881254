import Entity from '../entity'

export default class Video extends Entity<number>{
    course_id: number;
    specialty_id: number;
    description: string;
    video_date: string;
    url: string;
    facebook: string;
    active: boolean;
}


