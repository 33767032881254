import Entity from '../entity'

export default class Schedule extends Entity<number>{
    course_id: number;
    fee_id: number;
    name: string;
    days: string;
    start_hour: string;
    end_hour: string;
    nro_marks: number;
    active: boolean;
}


