import Entity from '../entity'

export default class VoteResumen extends Entity<number>{
    lawyer_id: number;
    response_id: number;
    ip: string;
    hour: string;
    option: string;
}


