import Entity from '../entity'

export default class Application extends Entity<number>{
    state_id: number;
    lawyer_id: number;
    type_id: number;
    lawyer_code: string;
    account: string;
    cci: string;
    application_date: string;
    amount: number;
    comments: string;
    filename: string;
    processing: boolean;
}


