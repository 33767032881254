import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CourseClass from '../../entities/courses/courseclass'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface CourseClassState extends ListState<CourseClass>{
    editModel: CourseClass;
    viewModel: CourseClass;
    hisLoading: boolean;
    getLoading: boolean;
    history: Array<any>;    
}
class CourseClassModule extends ListModule<CourseClassState, any, CourseClass>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CourseClass>(),
        activeList: new Array<CourseClass>(),
        loading: false,
        hisLoading: false,
        getLoading: false,
        editModel: new CourseClass(),
        history: new Array<any>(),
        path: 'course-classes',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.get('/api/course-classes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<CourseClass>;

            context.state.totalCount = page.total;
            context.state.list = page.data;
            context.state.list.forEach((item: any) => {
                item.stateColor = Util.abp.list.getItem(item.state_id).extra;
            });
        },
        async get(context: ActionContext<CourseClassState, any>, payload: any) {
            if (payload.id == undefined) return {};            
            context.state.getLoading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id);
            let item = reponse.data.result as CourseClass;
            context.state.getLoading = false;
            return item;
        },
        async getHistory(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/course-classes-history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async startEnroll(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/course-classes/start-enroll', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async stopEnroll(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/course-classes/stop-enroll', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async initProcess(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/course-classes/init-process', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async finish(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/course-classes/finish', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async getAvailable(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/course-classes-available', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = reponse.data.result;
        },
        async getFinished(context: ActionContext<CourseClassState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/course-classes-finished', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: CourseClassState, model: CourseClass) {
            var today = new Date();
            
            state.editModel = Object.assign({});
            state.editModel.capacity = Util.abp.setting.get('DEFAULT_CAPACITY');
            state.editModel.min_score = Util.abp.setting.get('DEFAULT_MIN_SCORE');
            state.editModel.min_attendance = Util.abp.setting.get('DEFAULT_MIN_ATTENDANCE');
            state.editModel.fee_id = null;
            state.editModel.payment_type = 'M';
            state.editModel.theme = '';            
            state.editModel.start_date = today.toISOString().substr(0,10);
            state.editModel.end_date = today.toISOString().substr(0, 10);
            state.editModel.marks_timeout = Util.abp.setting.get('MARKS_TIMEOUT');
            state.editModel.academic_hours = 0;            
            
        },
        edit(state: CourseClassState, model: CourseClass) {
            state.editModel = model;
        },
    });
}
const courseclassModule=new CourseClassModule();
export default courseclassModule;
