




























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
@Component({
    watch: {
    },
    computed: {
    }
})

export default class CusDatePicker extends Vue {
    name: string = 'cusDatePicker';
    @Prop({ type: Object }) item: object;
    @Prop({ type: Array }) value: Array<string>;
    //dates: Array<string> = [];
    visible: boolean = false;

    get dateFormatted() {
        return this.dates.join(' ~ ');
    }

    get dates(): Array<string> {
        return this.value;
    };

    set dates(value: Array<string>) {
        this.$emit('input', value);
    };



    updateModel(value: Array<string>) {
        if (value.length == 2)
            this.visible = false;
        this.$emit('input', value);
    };

    created() {

    }
}
