import Entity from '../entity'

export default class Teacher extends Entity<number>{
    address_id: number;
    gender_id: number;
    document_type_id: number;
    user_id: number;
    code: string;
    document: string;
    firstname: string;
    lastname: string;
    email: string;
    phone_mobile: string;
    active: boolean;
}


