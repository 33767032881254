











































import { Component, Vue,Inject,Prop,Emit } from 'vue-property-decorator';
import AbpBase from '../../../lib/abpbase'
@Component
export default class extends AbpBase {
    name:string='sidebarMenuShrink';
    @Prop({type:Array}) menuList:Array<any>;
    @Prop({default:'white'}) iconColor:string;
    @Prop({ default: 'darck' }) menuTheme: string;
    genChildTarget(item, subItem) {
        if (subItem.path) {
            return {
                name: subItem.path,
            };
        }
        return { name: `${item.path}/${(subItem.path)}` };
    }   
    @Emit('on-change') changeMenu(active:string){

    }
    itemTitle(item:any){
        return this.L(item.meta.title);
    }
}
