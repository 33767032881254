import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Currency from '../../entities/preferences/currency'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CurrencyState extends ListState<Currency>{
    editModel:Currency;
    activeList: Array<Currency>;
}
class CurrencyModule extends ListModule<CurrencyState,any,Currency>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Currency>(),
        loading:false,
        editModel: new Currency(),
        activeList: new Array<Currency>(),
        path: 'currencies'  
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const currencyModule=new CurrencyModule();
export default currencyModule;