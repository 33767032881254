import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Attendance from '../../entities/courses/attendance'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface AttendanceState extends ListState<Attendance> {
    editModel: Attendance;
}
class AttendanceModule extends ListModule<AttendanceState, any, Attendance>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Attendance>(),
        loading: false,
        editModel: new Attendance(),
        path: 'attendances',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra mutations
        async create(context: ActionContext<AttendanceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async createBatch(context: ActionContext<AttendanceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/attendances/batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async isMarked(context: ActionContext<AttendanceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/attendance-marked', { params: payload.data}).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const attendanceModule = new AttendanceModule();
export default attendanceModule;