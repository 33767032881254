import Entity from '../entity'

export default class ElectionApplicant extends Entity<number>{
    election_id: number;
    lawyer_id: number;
    position_id: number;
    name: string;
    votes: number;
    winner: boolean;
}


