import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Trade from '../../entities/registryoffices/trade'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface TradeState extends ListState<Trade>{
    editModel: Trade;
    hisLoading: boolean;
    history: Array<any>;  
}
class TradeModule extends ListModule<TradeState,any,Trade>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Trade>(),
        loading: false,
        hisLoading: false,
        editModel: new Trade(),
        activeList: new Array<Trade>(),
        history: new Array<any>(),
        path: 'trades'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getHistory(context: ActionContext<TradeState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/trades-history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: TradeState, model: Trade) {
            state.editModel = Object.assign({});
        },
        edit(state: TradeState, model: Trade) {
            state.editModel = model;
        },
    });
}
const tradeModule=new TradeModule();
export default tradeModule;
