import Entity from '../entity'

export default class DocumentType extends Entity<number>{
    code: string;
    name: string;
    reference: string;
    description: string;
    limit: number;
}


