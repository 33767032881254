declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router { 
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any; 
        children?: Array<Router>;
    } 
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import login from '../views/login.vue'
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import Ajax from '../lib/ajax';

export const locking = {
    path: '/locking',
    name: 'locking',
    component: () => import('../components/lockscreen/components/locking-page.vue')
};
export const loginRouter: Router = {
    path: '/',
    name: 'login',
    meta: {
        title: 'LogIn'
    },
    component: () => import('../views/login.vue')
};
export const otherRouters: Router = {
    path: '/main',
    name: 'main',
    permission: '',
    meta: { title: '' },
    component: main,
    children: [ 
        { path: 'home', meta: { title: 'Home' }, name: 'home', component: () => import('../views/home/home.vue') },
        { path: '/courses/course-class/view/:id', meta: { title: 'Clase' }, name: 'classview', component: () => import('../views/courses/courseclass/class-view.vue') },
        { path: '/elections/view/:id', meta: { title: 'Eleccion' }, name: 'electionview', component: () => import('../views/elections/election/election-view.vue') },
        { path: '/user/profile', meta: { title: 'Perfil de usuario' }, name: 'profile', component: () => import("../views/admin/user/profile.vue") },
        { path: '/reports', meta: { title: 'Reportes' }, name: 'reports', component: () => import("../views/reports/reports.vue") },
        { path: '/videos', meta: { title: 'Videos' }, name: 'videos', component: () => import("../views/videos/video/video.vue") }, 
        { path: '/reports/course-clasess', meta: { title: 'Reporte Cursos y Clases' }, name: 'rcourseclasess', component: () => import("../views/reports/general/course-clasess.vue") },
        { path: '/reports/course-attendances', meta: { title: 'Reporte Cursos y Asistencias' }, name: 'rcourseattendances', component: () => import("../views/reports/general/course-attendances.vue") },
        { path: '/reports/query-lawyers', meta: { title: 'Reporte Agremiados' }, name: 'rquerylawyers', component: () => import("../views/reports/general/query-lawyers.vue") },
        { path: '/reports/solicitud-25', meta: { title: 'Reporte Solicitud 25%' }, name: 'rapplication25', component: () => import("../views/reports/general/application-25.vue") },
        { path: '/reports/bloqueos', meta: { title: 'Reporte IPS Bloqueadas' }, name: 'rblacklist', component: () => import("../views/reports/general/query-blacklist.vue") },
        { path: '/reports/votaciones', meta: { title: 'Reporte Votaciones' }, name: 'rvotes', component: () => import("../views/reports/general/query-votes.vue") },
    ]
}
export const appRouters: Array<Router> = [{
    path: '/matricula',
    name: 'publicenroll',
    meta: {
        title: 'Matricula'
    },
    component: () => import('../views/public/enrollment/enrollment.vue'),
    children: []
},
{
    path: '/asistencia/:id',
    name: 'publicmark',
    meta: {
        title: 'Asistencia'
    },
    component: () => import('../views/public/attendance/mark.vue'),
    children: []
},
{
    path: '/habilitados',
    name: 'publicenabled',
    meta: {
        title: 'Habilitados'
    },
    component: () => import('../views/public/lawyer/enabled.vue'),
    children: [] 
},
{
    path: '/videoteca',
    name: 'publicvideoteca',
    meta: {
        title: 'Videoteca'
    },
    component: () => import('../views/public/videoteca/videoteca.vue'),
    children: [] 
},
{
    path: '/votaciones/:code',
    name: 'publicvoting',
    meta: {
        title: 'Votaciones'
    },
    component: () => import('../views/public/election/voting.vue'),
    children: []
}];

export const dbRouters = {
    user: () => import("../views/admin/user/user.vue"),
    role: () => import("../views/admin/role/role.vue"),
    //auditLog: () => import("../views/admin/auditLog/auditLog.vue"),
    tab: () => import("../views/admin/tab/tab.vue"),
    icon: () => import("../views/admin/icon/icon.vue"),
    company: () => import("../views/admin/company/company.vue"),
    
    student: () => import("../views/students/student/student.vue"),
    group: () => import("../views/students/group/group.vue"),
    documenttype: () => import("../views/students/documenttype/documenttype.vue"),
    gender: () => import("../views/students/gender/gender.vue"),

    attendance: () => import("../views/courses/attendance/attendance.vue"),
    courseclass: () => import("../views/courses/courseclass/class.vue"),
    teacher: () => import("../views/courses/teacher/teacher.vue"), 
    enrollment: () => import("../views/courses/enrollment/enrollment.vue"),
    course: () => import("../views/courses/course/course.vue"),
    certificate: () => import("../views/courses/certificate/certificate.vue"),

    lawyer: () => import("../views/lawyers/lawyer/lawyer.vue"),
    payment: () => import("../views/lawyers/payment/payment.vue"),
    //vote: () => import("../views/lawyers/vote/vote.vue"),

    election: () => import("../views/elections/election/election.vue"),
    position: () => import("../views/elections/position/position.vue"),
    blacklist: () => import("../views/elections/blacklist/blacklist.vue"),
    electionresult: () => import("../views/elections/results/election-results.vue"),

    application: () => import("../views/registryoffices/application/application.vue"),
    trade: () => import("../views/registryoffices/trade/trade.vue"),

    generaltype: () => import("../views/preferences/generaltype/generaltype.vue"),
    country: () => import("../views/preferences/country/country.vue"),
    department: () => import("../views/preferences/department/department.vue"),
    province: () => import("../views/preferences/province/province.vue"),
    district: () => import("../views/preferences/district/district.vue"),
    currency: () => import("../views/preferences/currency/currency.vue"),
    tax: () => import("../views/preferences/tax/tax.vue"),
    settings: () => import("../views/preferences/configuration/configuration.vue"),
    //language: () => import("../views/preferences/language/language.vue")
} 

export const routers = [
    loginRouter,
    locking,
    ...appRouters,
    otherRouters
];
