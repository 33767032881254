import Entity from '../entity'

export default class Enrollment extends Entity<number>{
    student_id: number;
    course_id: number;
    course_class_id: number;
    state_id: number;
    number: number;
    amount: number;
    notes: string;
    ranking: number;
    document_number: string;
    document_file: string;
    authorized: boolean;
    certified: boolean;
    scholarship: boolean;
}


