import Entity from '../entity'

export default class Blacklist extends Entity<number>{
    election_id: number;
    lawyer_id: number;
    ban_type_id: number;
    ip: string;
    browser_info: string;
    nro_attempts: number;
}


