import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Role from '../../entities/admin/role'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface RoleState extends ListState<Role> {
    editModel: Role;
    permissions: Array<string>
}
class RoleModule extends ListModule<RoleState, any, Role>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 50,
        list: new Array<Role>(),
        loading: false,
        editModel: new Role(),
        activeList: new Array<Role>(),
        permissions: new Array<string>(),
        path: 'roles'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async savePermission(context: ActionContext<RoleState, any>, payload: any) {
            await Ajax.put('/api/save-permission', payload.data).catch(e => {
                return Promise.reject(e);
            });
        },
        async revokePermission(context: ActionContext<RoleState, any>, payload: any) {
            await Ajax.put('/api/revoke-permission', payload.data).catch(e => {
                return Promise.reject(e);
            });
        },
        async getAllPermissions(context: ActionContext<RoleState, any>) {
            let reponse = await Ajax.get('/api/get-permissions');
            context.state.permissions = reponse.data.result;
        }
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const roleModule = new RoleModule();
export default roleModule;

