import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Vote from '../../entities/elections/vote'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface VoteState extends ListState<Vote>{
    editModel: Vote;
    reportList: Array<any>;
    filename: string; 
}
class VoteModule extends ListModule<VoteState,any,Vote>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Vote>(),
        loading:false,
        editModel: new Vote(),
        activeList: new Array<Vote>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'votes'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<VoteState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/votes/' + payload.data.key, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async validate(context: ActionContext<VoteState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/votes/validate', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async reportVotes(context: ActionContext<VoteState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/votes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra 
    });
}
const voteModule=new VoteModule();
export default voteModule;
