





























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import util from '@/lib/util';

class Ubigeo {
    department: number = null;
    province: number = null;
    district: number = null;
}

@Component({
    watch: {
        loading: function (val, oldValue) {
            if (!val && oldValue) {
                (this as any).init((this as any).value);
            }
        }
    }
})

export default class UbigeoField extends Vue {
    name: string = 'ubigeo';
    @Prop({ type: Object }) item: any;
    @Prop({ type: [Object, Number] }) value: null;
    model: Ubigeo = new Ubigeo();

    get departments() {
        return this.$store.state.department.activeList;
    }

    get provinces() {
        let list = [];
        let dep = util.getEntity(this, 'department', this.model.department);
        if (dep != null) {
            list = dep.provinces;
        }           

        return list;
    }

    get districts() {
        let list = [];
        let prov = util.findByParam(this.provinces, 'id', this.model.province)[0];
        if (prov != null) {
            list = prov.districts;
        }

        return list;
    }

    get loading() {
        return this.$store.state.department.loading;
    }

    get districtList() {
        let list = [];
        this.departments.forEach(dep => {
            dep.provinces.forEach(prov => {
                prov.districts.forEach(dis => {
                    dis.department_id = dep.id;
                    list.push(dis);
                });
            });
        });
        return list;
    }

    change(type) {
        if (type == 'dep') 
            (this.$refs.prov as any).focus();
        else if (type == 'pro')
            (this.$refs.dist as any).focus();

        if (this.item.returnObject)
            this.$emit('input', this.model);
        else
            this.$emit('input', this.model.district);
    }        

    init(value) {
        this.$emit('input', value);
        if (this.value == undefined || this.value == null) {
            this.model.district = null;
            this.model.province = null;
            this.model.department = null;
        }
        if (typeof (this.value) == 'object' && this.value != null) {
            this.model = this.value;
        } else {
            let data = util.findByParam(this.districtList, 'id', this.value)[0];
            if (data != null) {
                this.model.district = data.id;
                this.model.province = data.province_id;
                this.model.department = data.department_id;
            }
        }
    }

    created() {

    }
}
