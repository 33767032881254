import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Department from '../../entities/preferences/department'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DepartmentState extends ListState<Department>{
    editModel:Department;
    activeList: Array<Department>;
}
class DepartmentModule extends ListModule<DepartmentState,any,Department>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Department>(),
        loading:false,
        editModel: new Department(),
        activeList: new Array<Department>(),
        path: 'departments'  
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations        
    });
}
const departmentModule=new DepartmentModule();
export default departmentModule;