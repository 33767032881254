import Entity from '../entity'

export default class Certificate extends Entity<number>{
    certificate_template_id: number;
    course_id: number;
    course_class_id: number;
    issue_date: string;
    sent: boolean;
    nro_sent: number;
}


