import Entity from '../entity'

export default class Vote extends Entity<number>{
    election_id: number;
    lawyer_id: number;
    choice_id: number;
    choices: string;
    ip: string;
    browser_info: string;
    lat: number;
    lng: number;
}


