































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import Util from '@/lib/util'

@Component({
    watch: {
        items: function (val) {
            (this as any).modelValues = (this as any).currentModel;
            (this as any).updateChanges();
        }
    }
})

export default class SortableList extends Vue {
    name: string = 'sortableList';
    @Prop({ type: Array, default: () => { return [] } }) value: Array<any>;
    @Prop({ type: Array, default: () => { return [{ id: 0, prop: 'descripcion', size: 12 }] } }) items: Array<any>;
    @Prop({ type: Array, default: () => { return [{ type: 'text', prop: 'descripcion', label: '', size: 12 }] } }) fields: Array<any>;
    @Prop({ type: String, default: 'nombre' }) itemText: string;
    @Prop({ type: String, default: 'id' }) itemTag: string;
    @Prop({ type: String, default: 'entityId' }) entityId: string;
    @Prop({ type: Array }) model: Array<any>;
    modelValues: Array<any> = this.currentModel;

    updateChanges() {
        let inputList = [];
        this.modelValues.forEach((item, inx) => {
            let input = Util.extend(true, {}, item);
            input[this.entityId] = inx;
            inputList.push(input);
        });
        this.$emit('input', inputList);
    };

    get currentModel() {
        let values = new Array<any>();
        this.items.forEach(item => {
            let data: any = {};

            if (this.value.length > 0) {
                let ent = Util.findByParam(this.value, this.entityId, item.id);
                if (ent.length > 0) {
                    values[item.id] = ent[0];
                }
            }

            if (values[item.id] == undefined) {
                values[item.id] = data;
                this.fields.forEach(field => {
                    data[field.prop] = null;
                });
            }
        });

        return values;
    }

    created() {
        this.updateChanges();
    }

}
