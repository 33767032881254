import { Store, Module, ActionContext, MutationTree } from 'vuex'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DashboardState {
    activity: any;
    sales: any;
    widgets: Array<any>;
    loading: boolean;
    
}
class DashboardModule implements Module<DashboardState, any>{
    namespaced = true;
    state={
        activity: {
            total_clases: 0,
            total_students: 0,
            total_teachers: 0,
            total_lawyers: 0,
            total_applications: 0,
        },
        sales: [],
        widgets: [],
        loading:false,
    }
    actions = {
        async getDashActivity(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/dash-activities', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            if (reponse) {
                context.state.loading = false;
                context.state.activity = (reponse as any).data.result;
                return true;
            } else 
                return false;
        },
        async getDashSales(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/dash-sales', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            if (reponse) {
                context.state.loading = false;
                context.state.sales = (reponse as any).data.result;
                return true;
            } else
                return false;
        },
        async getWidgetValues(context: ActionContext<DashboardState, any>, payload: any) {
            let reponse = await Ajax.get('/api/dash-widgets', { params: payload.data }).catch(e => {
                return false;
            });
            if (reponse) {
                context.state.widgets = (reponse as any).data.result;
                return context.state.widgets;
            } else
                return false;
        },
    };
    mutations = {
        
    };
}
const dashboardModule = new DashboardModule();
export default dashboardModule;