import Entity from '../entity'
import Course from './course';
import Teacher from './teacher';

export default class Lesson extends Entity<number>{
    course_id: number;
    course_class_id: number;
    teacher_id: number;
    state_id: number;
    capacity: number;
    nro_attendees: number;
    lesson_date: string;
    start_hour: string;
    end_hour: string;
    link: string;
    notes: string;
    opened: boolean;
    closed: boolean;
    canceled: boolean;

    course: Course;
    teacher: Teacher;
}


