













































































































import { Component, Vue,Inject, Prop,Watch } from 'vue-property-decorator';
import AbpBase from '../../lib/abpbase'
@Component
export default class Notice extends AbpBase{
    noticeSpinShow: boolean = true;
    tabModel: any = null;

    fav: boolean = true;
    menu: boolean = false;
    message: boolean = false;
    hints: boolean = true;

    get noticeList(): Array<any>{
        return [];
        //return this.$store.state.app.noticeList;
    }
    get unReadCount() {
        return 0;
        //return this.noticeList.filter(n=>n.read==false).length
    }
    get noticeArray() {
        return [];
        //return this.noticeList.filter(n=>n.read==false&&n.type===0)
    }
    get noticeCount() {
        return 0;
        //return this.noticeList.filter(n=>n.read==false&&n.type===0).length
    }
    get messageArray(){
        return [];
        //return this.noticeList.filter(n=>n.read==false&&n.type===1)
    }
    get messageCount() {
        return 0;
        //return this.noticeList.filter(n=>n.read==false&&n.type===1).length
    }
    getNotices(){
        setTimeout(()=>{
            this.noticeSpinShow=false;
        },2000)
    }
}
