import Entity from '../entity'

export default class Company extends Entity<number>{
    address_id: number;
    manager_id: number;
    name: string;
    name_legal: string;
    document: string;
    email: string;
    website: string;
    note: string;
    active: boolean;
    address: any;
}


