import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Schedule from '../../entities/courses/schedule'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ScheduleState extends ListState<Schedule>{
    editModel: Schedule;
    course: number;
    activeList: Array<Schedule>;
}
class ScheduleModule extends ListModule<ScheduleState, any, Schedule>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Schedule>(),
        loading:false,
        editModel: new Schedule(),
        course: null,
        activeList: new Array<Schedule>(),
        path: 'schedules'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ScheduleState, any>, payload: any) {
            context.state.loading = true;
            payload.data.course = context.state.course;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
        },
        async create(context: ActionContext<ScheduleState, any>, payload: any) {
            context.state.loading = true;
            payload.data.course_id = context.state.course;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setCourse(state: ScheduleState, course: number) {
            state.course = course;
        },
        setActiveList(state: ScheduleState, list: any) {
            state.activeList = list;
        }
    });
}
const scheduleModule=new ScheduleModule();
export default scheduleModule;
