import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import GeneralType from '../../entities/preferences/generaltype'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '../../entities/page-result';

interface GeneralTypeState extends ListState<GeneralType>{
    editModel: GeneralType;
    listValues: Array<any>;
}
class GeneralTypeModule extends ListModule<GeneralTypeState,any,GeneralType>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize:15,
        list: new Array<GeneralType>(),
        loading:false,
        editModel: new GeneralType(),
        listValues: new Array<any>(),
        path: 'types', 
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getValuesByCode(context: ActionContext<GeneralTypeState, any>, payload: any) {
            if (context.state.loading) return;
            context.state.loading = true;

            if (context.state.listValues.length == 0) {
                let reponse = await Ajax.get('/api/general-types-values');
                context.state.listValues = reponse.data.result;
            } 

            //let gType = Util.findByParam(context.state.listValues, 'code', payload.data.code)[0];
            //if (gType == null) {
            //    let reponse = await Ajax.get('/api/services/app/GeneralType/GetValuesByCode', { params: payload.data });
            //    let obj = new GeneralType();
            //    obj.code = payload.data.code;
            //    obj.values = reponse.data.result.items;
            //    context.state.listValues.push(obj);
            //} 
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const generaltypeModule=new GeneralTypeModule();
export default generaltypeModule;