import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import GeneralTypeValue from '../../entities/preferences/generaltypevalue'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface GeneralTypeValueState extends ListState<GeneralTypeValue>{
    editModel:GeneralTypeValue;
    
}
class GeneralTypeValueModule extends ListModule<GeneralTypeValueState,any,GeneralTypeValue>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<GeneralTypeValue>(),
        loading:false,
        editModel: new GeneralTypeValue()
    }
    actions = {
        async getAll(context: ActionContext<GeneralTypeValueState, any>, payload: any) {
            //context.state.loading = true;
            //let reponse = await Ajax.get('/api/services/app/' + (context.state as any).entity + '/GetAll', { params: payload.data });
            //context.state.loading = false;
            //let page = reponse.data.result as PageResult<U>;
            //context.state.totalCount = page.totalCount;
            //context.state.list = page.items;
        },
        async create(context: ActionContext<GeneralTypeValueState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/type-values', payload.data);
            context.state.loading = false;
        },
        async update(context: ActionContext<GeneralTypeValueState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/type-values/' + payload.data.id, payload.data);
            context.state.loading = false;
        },
        async delete(context: ActionContext<GeneralTypeValueState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.delete('/api/type-values/' + payload.data.id);
            context.state.loading = false;
        },
        async get(context: ActionContext<GeneralTypeValueState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/type-values/get/' + payload.id);
            context.state.loading = false;
            return reponse.data.result as GeneralTypeValue;
        }
    };
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setList(state: GeneralTypeValueState, values: Array<GeneralTypeValue>) {
            if (values != null) {
                state.loading = true;
                state.list = values;
                state.totalCount = values.length;
                state.loading = false;
            }
        }
    });
}
const generaltypevalueModule=new GeneralTypeValueModule();
export default generaltypevalueModule;