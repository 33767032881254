import Entity from '../entity'
import GeneralTypeValue from './generaltypevalue'

export default class GeneralType extends Entity<number>{
    code: string;
    name: string;
    editable: boolean;
    values: GeneralTypeValue[]
}


